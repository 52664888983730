/* eslint-disable */
import Swiper from "swiper";
export default {
  init() {
    // JavaScript to be fired on the about page

    // Team slider
    var aboutSwiper = new Swiper(".about-swiper", {
      slidesPerView: 1.1,
      spaceBetween: 25,
      loop: false,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        990: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
      },
    });
  },

  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
