/* eslint-disable */
import Swiper, { Autoplay, Pagination } from "swiper";
export default {
  init() {
    // JavaScript to be fired on the blog page

    // Banner slider
    var banneSwipper = new Swiper(".blog__banner-slider", {
      modules: [Pagination, Autoplay],
      spaceBetween: 30,
      autoplay: true,
      loop: true,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: ".swiper-pagination",
      },
    });
  },

  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
