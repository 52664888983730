/* eslint-disable */
import AOS from "aos";
import Swiper from "swiper";

export default {
  init() {
    if (window.innerWidth < 1025) {
      $(
        ".header-navigation--left .header-navigation__list > .menu-item.menu-item-has-children"
      ).each(function () {
        const menuItem = $(this);

        // Wrap <a> in a new element with class "childSubs"
        menuItem.find("> a").wrap('<div class="childSubs"></div>');

        // Add sub-menu--toggle element inside the childSubs div
        menuItem.find(".childSubs").append(`
          <span class="sub-menu--toggle">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
              <path d="M1 1L8.5 8L16 1" stroke="black" stroke-width="2"/>
            </svg>
          </span>`);

        // Click event handler
        menuItem.find(".sub-menu--toggle").click(function () {
          menuItem.find(".sub-menu").slideToggle("medium");
          menuItem.find(".sub-menu--toggle").toggleClass("arrow-open");
        });

        // Prevent clicks on the toggle from triggering the sub-menu toggle
        menuItem.find(".sub-menu--toggle").click(function (event) {
          event.stopPropagation();
        });
      });
    }

    AOS.init({ duration: 1000, offset: 30, disable: "tablet", once: true });

    var hamburgerMenu = document.querySelector(".hamburger");
    var headerNav = document.querySelector(".header-navigation");
    var logo = document.querySelector(".logo");
    var htmls = document.querySelector("html");
    const $homeIframeToggle = $(".toggle-iframe");
    $homeIframeToggle.click(function (e) {
      e.preventDefault();
      $(this).parent().find("iframe")[0].src += "?autoplay=1";
      $(this).fadeOut();
    });

    hamburgerMenu.addEventListener("click", () => {
      headerNav.classList.toggle("bg-blue--open");
      hamburgerMenu.classList.toggle("animated");

      logo.classList.toggle("active");
      document.body.classList.toggle("open");
      $("html").toggleClass("open");
    });

    $(".language_menu li.menu-item-has-children").each(function () {
      $(this).append(
        `<span class="sub-menu--toggle">
          <svg xmlns="http://www.w3.org/2000/svg" width="10.752" height="6.79" viewBox="0 0 10.752 6.79">
            <path id="Path_45" data-name="Path 45" d="M5389.58,1252.651l3.962,3.962,3.961-3.962" transform="translate(-5388.166 -1251.237)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
          </svg>
        </span>`
      );
      $(this)
        .find(".sub-menu--toggle")
        .click(function () {
          // $(this).parent().find(".sub-menu").slideToggle("medium");
          $(this).parent().find(".sub-menu").toggleClass("openedLang");
          $(this).parent().find("a").toggleClass("openedLi");
          $(this)
            .parent()
            .find(".sub-menu--toggle")
            .toggleClass("arrow-opened");
        });
    });

    var TxtType = function (el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = "";
      this.tick();
      this.isDeleting = false;
    };

    TxtType.prototype.tick = function () {
      var i = this.loopNum % this.toRotate.length;
      var fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = "" + this.txt + "";

      var that = this;
      var delta = 200 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === "") {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(function () {
        that.tick();
      }, delta);
    };

    var productSlider = new Swiper(".productSlider", {
      autoplay: false,
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      breakpoints: {
        1400: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
      },
    });

    let listItems = document.getElementsByClassName("language_menu");
    for (let i = 0; i < listItems.length; i++) {
      var anchor = listItems[i].getElementsByTagName("a")[0];
      // or let anchor = listItems[i].querySelector("a");
    }
    let form = document.getElementById("gform_1");
    if (form) {
      form.addEventListener("submit", (event) => {
        event.preventDefault();

        //get input values
        let input1 = form.elements["input_4"].value;
        let input2 = form.elements["input_1"].value;
        let removeComma = input1.split(",").join("");

        //send currency, language and money value
        if (anchor.title === "AL") {
          return (window.location.href = `https://app.loresplus.com/?lang=sq&amount=${removeComma}&currency=${input2}`);
        } else {
          window.location.href = `https://app.loresplus.com/?lang=${anchor.title}&amount=${removeComma}&currency=${input2}`;
        }

        // do something when the form is submitted
      });
    }

    window.onload = function () {
      var elements = document.getElementsByClassName("typewrite");
      for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute("data-type");
        var period = elements[i].getAttribute("data-period");
        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
      }
    };

    // Scroll Animation
    $('a[href*="#"]')
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function (event) {
        if (
          location.pathname.replace(/^\//, "") ==
            this.pathname.replace(/^\//, "") &&
          location.hostname == this.hostname
        ) {
          var target = $(this.hash);
          target = target.length
            ? target
            : $("[name=" + this.hash.slice(1) + "]");
          if (target.length) {
            event.preventDefault();
            $("html, body").animate(
              { scrollTop: target.offset().top - 50 },
              1000,
              function () {
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) {
                  return false;
                } else {
                  $target.attr("tabindex", "-1");
                  $target.focus();
                }
              }
            );
          }
        }
      });
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
