/* eslint-disable */
import Swiper, { Navigation, EffectFade, Autoplay, Controller } from "swiper";
export default {
  init() {
    // JavaScript to be fired on the home page
    const $homeIframeToggle = $(".toggle-iframe");
    $homeIframeToggle.click(function (e) {
      e.preventDefault();
      $(this).parent().find("iframe")[0].src += "?autoplay=1";
      $(this).fadeOut();
    });

    // Box Slider
    var bannerSlide = new Swiper(".bannerSlide", {
      modules: [EffectFade, Autoplay],
      effect: "fade",
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
      autoplay: {
        delay: 5500,
        disableOnInteraction: false,
      },
    });

    //Navigation to left
    const header = document.querySelector(".header-navigation--left");
    header.classList.add("ml-0");

    //Navigation white
    const headerBtns = document.querySelector(".header-right__list");
    headerBtns.classList.add("header-right__list--white");

    //Banner input type
    const inputContainer = document.querySelector(".value-currency");
    const inputNode =
      inputContainer.querySelector(".ginput_container").children[0];

    function moneyValue(val) {
      if (typeof val === "undefined" || val === 0) return;
      return new Intl.NumberFormat().format(Number(val));
    }

    inputNode.addEventListener("input", (e) => {
      let value = e.target.value
        .replace(".", "")
        .replace(",", "")
        .replace(/\D/g, "")
        .substring(0, 9);
      let formatedValue = moneyValue(value);
      e.target.value = formatedValue;
    });

    //Scroll down arrow
    $(document).ready(function () {
      $("#btn-down").click(function () {
        $("html, body").animate(
          {
            scrollTop: $(".video-content").offset().top + 70,
          },
          1000
        );
      });
    });
    // Box Slider
    var catSwiper = new Swiper(".home-slider-one", {
      modules: [Navigation],
      slidesPerView: "auto",
      spaceBetween: 45,
      loop: false,
      navigation: {
        nextEl: ".swiper-button-next-one",
        prevEl: ".swiper-button-prev-one",
      },
    });

    // Blog Slider
    var blogSwiper = new Swiper(".blog-slider-one", {
      modules: [Navigation],
      slidesPerView: "auto",
      loop: false,
      spaceBetween: 26,
    });

    // Product Slider
    var catSwipertwo = new Swiper(".home-slider-product", {
      modules: [Navigation],
      slidesPerView: "auto",
      spaceBetween: 17,
      loop: false,
      navigation: {
        nextEl: ".swiper-button-next-product",
        prevEl: ".swiper-button-prev-product",
      },
      breakpoints: {
        767: {
          spaceBetween: 40,
        },
      },
    });

    // Quote slider
    var quoteSwiper = new Swiper(".quote-swiper", {
      modules: [Navigation, EffectFade, Controller],
      slidesPerView: 1,
      effect: "fade",
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next-quote",
        prevEl: ".swiper-button-prev-quote",
      },
    });

    var quoteSwiperTwo = new Swiper(".quoteTwo-swiper", {
      modules: [Navigation, EffectFade, Controller],
      slidesPerView: 1,
      effect: "fade",
      spaceBetween: 10,
    });

    quoteSwiper.controller.control = quoteSwiperTwo;
    quoteSwiperTwo.controller.control = quoteSwiper;

    // Team slider
    var swiper = new Swiper(".about-slider-one", {
      modules: [Navigation],
      slidesPerView: "auto",
      spaceBetween: 45,
      loop: false,
      navigation: {
        nextEl: ".swiper-button-next-one",
        prevEl: ".swiper-button-prev-one",
      },
    });

    // //Video banner autoplay  when in view
    // const videoBanner = document.querySelector(".video-banner");
    // const videoFrame = document.querySelector(".iframe-video");

    // let videoStarted = false;
    // let videoStopped = true;

    // let videoURL = $("#iframeVideo").prop("src");
    // let playURL = (videoURL += "?autoplay=1&mute=1");
    // let pauseURL = videoURL.replace("?autoplay=1&mute=1", "");

    // //Logic blocks
    // function videoInView(el) {
    //   const element = el.getBoundingClientRect();
    //   const position =
    //     element.top <= 0 && element.top >= Number("-" + element.height);
    //   if (videoStarted) return false;
    //   else if (position && !videoStarted) {
    //     videoStarted = true;
    //     videoStopped = false;
    //   }
    //   return position;
    // }

    // function videoOutOfView(el) {
    //   const element = el.getBoundingClientRect();
    //   if (!videoStarted || videoStopped) return false;

    //   const position =
    //     (element.top < -1101 || element.top > 200) && videoStarted;
    //   if (position) videoStopped = true;
    //   return position;
    // }

    // function handleVideoInView() {
    //   if (videoInView(videoBanner)) {
    //     setTimeout(() => {
    //       videoFrame.setAttribute("src", playURL);
    //     }, 2000);
    //   } else if (videoOutOfView(videoBanner)) {
    //     videoFrame.setAttribute("src", pauseURL);
    //   }
    // }

    //Counter function
    function counter() {
      $(".counterJs").each(function () {
        $(this)
          .prop("Counter", 0)
          .animate(
            {
              Counter: $(this).text(),
            },
            {
              duration: 4000,
              easing: "swing",
              step: function (now) {
                $(this).text(Math.ceil(now));
              },
            }
          );
      });
    }

    //Counter autoplay when in view
    const counterContainer = document.querySelector(".number-banner");

    let counterStarted = false;
    let counterScrolled = 0;

    function counterInView() {
      const element = counterContainer.getBoundingClientRect();

      const scrolled = 2085;
      const scrolledY = window.scrollY;
      if (scrolledY >= scrolled && !counterStarted) {
        counterStarted = true;
        counter();
      }
      if (scrolledY < scrolled && counterStarted) {
        counterStarted = false;
      }
    }

    //Scroll into view watcher
    window.addEventListener("scroll", () => {
      const scrolled = window.scrollY;
      counterInView();
    });
  },

  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
